// Module
var code = `<template>
  <require from="./bitcoin_generate_qr_code.scss"></require>
  <require from="../../../components/card-banka-detailled/card-banka-detailled"></require>
  <require from="../../../components/header-titles/header-title-qr/header-title-qr"></require>
  <require from="../../../components/ValueConverters/currency-denomination"></require>
  <require from="../../../components/ValueConverters/currency-formatter/currency-formatter"></require> 
  <require from="../../../components/number-format"></require>
  <section id="bitcoin_generate_qr_code" class="flex-column">
    <header-title-qr title.bind="pageTitle"></header-title-qr>
    <!-- <header-standard show-back-button.bind="showBackButton" show-message.bind="showMessage" message.bind="message" show-logo.bind="showLogo" show-language.bind="showLanguage"></header-standard>     -->
    <div class="flex-grow"></div>
    <form id="payment_form" class="flex-column" 
      class.bind="(isSelectingRecipient || isSelectingSender) ? 'selecting' : ''"
      submit.delegate="gotToQrCodeReceive(\$event)">
  
      <!-- Amount part (payment_price) -->
      <div id="payment_price" class="flex-row">
        <input disabled.bind="locked" id="qrCodeAmount" type="text"
          value.bind="formattedAmount"
          placeholder="00.00 000 000" required="required"
          class.bind="isAmountError ? 'error' : ''"
          input.trigger="onAmountInputBTC(\$event)" 
          autofocus/>
        <p id="currency" class="currency">\${state.wallets[state.selectedAccountIndex].currency}</p>
      </div>

      <!-- Comment part (payment_reference) -->
      <div id="payment_reference" class="flex-row">
        <input disabled.bind="locked" ref="input_reference" id="qrCodeReference" type="text" value.bind="qrCodeReference"
              placeholder.bind="getRandomCommunication()" class.bind="isCommunicationError ? 'error' : ''"
              focus.delegate="input_reference.placeholder='no'"
              onkeypress="return /[0-9a-zA-Z- ]/i.test(event.key)"
              input.delegate="sanitizeCommentary(qrCodeReference)">
      </div>

      
      <!-- Action button part -->
      
    </form>
    <div class="flex-grow"></div>
    <div class="confirm">
      <button class="btn btn-primary" if.bind="qrCodeAmount" 
              click.delegate="goToQrCodeReceive()" i18n="home.next" form="payment_form">Suivant</button>
      <button class="btn btn-primary fakeDisabled" if.bind="!qrCodeAmount" 
              click.delegate="shakeError()" t="home.next"></button>
    </div>
    <div class="spacer-16"></div>
  </section>
</template>
`;
// Exports
export default code;